<template>
  <Dialog style="width: 40%" v-model:visible="show" header="查看诉求">
    <div>
      <h5 style="font-weight: 600;text-align: center">{{ data.title }}</h5>
      <p>提交时间：{{ new Date(data.submitTime).toLocaleString() }} </p>
      <p>所属行业：{{ data.industry }}</p>
      <p>希望邀请：{{ data.type }}</p>
      <p>提交企业：{{ data.companiesName }}</p>
      <div class="p-mt-2" v-html="data.content"></div>
      <div style="display: flex;justify-content: space-between;margin: 1.5rem">
        <Button @click="update(data.id,1)">受理</Button>
        <Button @click="update(data.id,2)">拒绝</Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "checkAndchange",
  data() {
    return {
      show: false,
      data: {}
    }
  },
  methods: {
    init(data) {
      this.show = true
      this.data = data
    },
    update(id, state) {
      this.$http.post('/demands/Accept?id=' + id + '&state=' + state).then(() => {
        this.$toast.add({severity: 'success', summary: '受理成功', life: 3000})
        setTimeout(()=>{this.$emit('close')},1500)
      })
    }
  }
}
</script>

<style scoped>

</style>