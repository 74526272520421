<template>
  <div class=" card ">

    <Breadcrumb :model="userType==1?items:comItems"/>

    <DataTable class="p-mt-3" style="width: 100%" ref="dt" :value="data"
               :paginator="true" :rows="10" :auto-layout="true"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
      <template #header>
        <div class="p-d-flex ">
          <Button v-if="userType==2" label="新增诉求" class="p-mr-2" icon="pi pi-plus" @click="openNew"/>
          <InputText v-model="title" placeholder="问题名称"/>
          <InputText class="p-ml-2" v-model="companiesName" placeholder="公司名称"/>
          <Dropdown class="p-ml-2" v-model="industry" :options="BelongIndustry" optionLabel="name"
                    placeholder="所属行业" option-value="name" :show-clear="true"/>
          <Calendar onfocus=this.blur()
                    class="p-ml-2" v-model="time"
                    @clear-click="demandTermQuery"
                    placeholder="发布时间" showButtonBar="true" dateFormat="yy-mm-dd"/>
          <Dropdown class="p-ml-2" v-model="state" :options="states" optionLabel="name"
                    placeholder="问题状态"
                    option-value="code" :show-clear="true"/>
          <Button class="p-ml-2" @click="demandTermQuery">查询</Button>
        </div>
      </template>
      <Column field="title" header="标题"></Column>
      <Column field="companiesName" header="发起企业名称"></Column>
      <Column field="type" header="希望邀请"></Column>
      <Column field="content" header="内容摘要">
        <template #body="slotProps">
          <div v-html="slotProps.data.brief"></div>
        </template>
      </Column>
      <Column field="submitTime" header="提交时间">
        <template #body="slotProps">
          {{
            new Date(+new Date(slotProps.data.submitTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
          }}
        </template>
      </Column>
      <Column field="state" header="受理状态" :sortable="true">
        <template #body="slotProps">
          <label v-if="slotProps.data.state==0">待响应</label>
          <label v-if="slotProps.data.state==1">已受理</label>
          <label v-if="slotProps.data.state==2">婉拒</label>
        </template>
      </Column>
      <Column header="操作">
        <template #body="slotProps">
          <Button v-if="userType==1&&slotProps.state==0" @click="checkAndChange(slotProps.data)" class="p-mr-2 p-button-sm">查看并受理</Button>
          <Button v-if="userType==2" class="p-button-sm p-mr-2" label="编辑"
                  @click="openNew(slotProps.data)"/>
          <Button class="p-button-danger p-button-sm" label="删除"
                  @click="delDialogShow(slotProps.data)"/>
        </template>
      </Column>
    </DataTable>
    <check-andchange @close="closeCheck" v-if="checkShow" ref="check"></check-andchange>
    <RequirementsRelease @close="closeDialog" v-if="dialogIsShow" ref="dialog"></RequirementsRelease>
    <Toast/>
    <Dialog header="确认框" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span>你确认删除吗?</span>
      </div>
      <template #footer>
        <Button label="是" icon="pi pi-check" @click="removeSelected"/>
        <Button label="否" icon="pi pi-times" @click="closeModal" class="p-button-secondary"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import RequirementsRelease from "@/views/modules/businessRequirements/RequirementsRelease";
import CheckAndchange from "@/views/platform/memberManagement/checkAndchange";

export default {
  components: {CheckAndchange, RequirementsRelease},
  name: null,
  data() {
    return {
      displayConfirmation: false,
      delData: {},
      checkShow: false,
      userType: null,
      dialogIsShow: false,
      title: null,
      companiesName: null,
      industry: null,
      time: null,
      state: null,
      display: false,
      selectedCompany: null,
      selectedIndustry: null,
      selectedStates: null,
      date: null,
      selectedCity: null,
      cities: [],
      items: [
        {label: '企业会员管理', to: '/'},
        {label: '企业需求受理', to: '/'}
      ],
      comItems: [{label: '诉求管理', to: '/'},],
      company: [
        {name: '蓝金科技有限公司', code: '1'},
        {name: '西安科技有限公司', code: '2'},
        {name: '西电集团', code: '3'},
        {name: '加盟有限公司', code: '4'},
        {name: '服装有限公司', code: '5'}
      ],
      BelongIndustry: [],
      states: [
        {name: '待响应', code: 0},
        {name: '已受理', code: 1},
        {name: '已拒绝', code: 2}
      ],
      data: []
    }
  },
  mounted() {

    this.getIndustry()
    this.getData()
    this.userType = JSON.parse(localStorage.getItem('userInfo')).userType

  },
  methods: {

    closeModal() {
      this.displayConfirmation = false
    },
    getIndustry() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }

        this.BelongIndustry = resList;
      })
    },
    getData() {
      this.$http.get('/demands/getAll').then((res) => {
        this.data = res.data
        console.log(res.data)
      })
    },
    openNew(data) {

      this.dialogIsShow = true;
      this.$nextTick(() => {
        this.$refs.dialog.init(data)
      })

    },
    closeDialog() {
      this.dialogIsShow = false
      this.getData()
    },

    demandTermQuery() {
      let time = new Date(this.time == "" ? 1970 - 1 - 1 : this.time)
      time = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
      this.$http.get('/demands/demandTermSelect', {
        params: {
          'title': this.title,
          'companiesName': this.companiesName,
          'industry': this.industry,
          'submitTime': time,
          'state': this.state
        }
      }).then((res) => {
        this.data = res.data
      })
    },
    checkAndChange(data) {
      this.checkShow = true
      this.$nextTick(() => {
        this.$refs.check.init(data)
      })
    },
    getExporetList() {
      this.display = true
      this.$http.get('/demands/getExportList').then((res) => {
        this.cities = res.data
        console.log(res.data)
      })
    },
    closeCheck() {
      this.checkShow = false
      this.getData()
    },
    delDialogShow(data) {
      this.displayConfirmation = true
      this.delData = data
    },
    removeSelected() {
      this.$http.get('/companiesDemands/remove', {params: {ids: this.delData.id}}).then(() => {
        this.dialogIsShow = false;
        this.displayConfirmation = false
        this.$toast.add({severity: 'success', summary: '删除成功', life: 3000})
        this.getData()
      });
    },
  }
}
</script>

<style scoped>

</style>